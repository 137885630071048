import React from 'react';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

import originalQuestions from '../../content/data/questions.json';

function calcQuestions(originalQuestions, license) {
	if (['B2', 'C', 'D', 'E', 'F'].includes(license)) {
		return originalQuestions;
	}
	if (license === 'B1') {
		return originalQuestions.filter(function (question) {
			return question.b1 > 0;
		});
	}
	if (['A3', 'A4'].includes(license)) {
		return originalQuestions.filter(function (question) {
			return question.a3 > 0;
		});
	}
	if (license === 'A1') {
		return originalQuestions.filter(function (question) {
			return question.a1 > 0;
		});
	}
	if (license === 'A2') {
		return originalQuestions.filter(function (question) {
			return question.a2 > 0;
		});
	}
	return [];
}

class BlogIndex extends React.Component {
	render() {
		const { data } = this.props;
		const siteTitle = data.site.siteMetadata.title;
		const posts = data.allMarkdownRemark.edges;

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title="All posts" keywords={[`blog`, `gatsby`, `javascript`, `react`]} />
				<Bio />
				{posts.map(({ node }) => {
					const title = node.frontmatter.title || node.fields.slug;
					return (
						<div key={node.fields.slug}>
							<h3
								style={{
									marginBottom: rhythm(1 / 4),
								}}
							>
								<Link
									style={{ boxShadow: `none` }}
									to={`/posts${node.fields.slug}`}
								>
									{title}
								</Link>
							</h3>
							<small>{node.frontmatter.date}</small>
							<p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
						</div>
					);
				})}
			</Layout>
		);
	}
}

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
			edges {
				node {
					excerpt
					fields {
						slug
					}
					frontmatter {
						date(formatString: "MMMM DD, YYYY")
						title
					}
				}
			}
		}
	}
`;
